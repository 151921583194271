<template>
  <div class="index-layout">
    <router-view />
    <ModalsContainer />
  </div>
</template>

<script>
/**
 * @layout Index
 * Лейаут для главной страницы сайта
 */

export default {
  name: "LandingLayout",

  created() {
    document && document.documentElement.classList.add("landing-layout");
  },
  beforeDestroy() {
    document && document.documentElement.classList.remove("landing-layout");
  },
};
</script>
